import { Controller } from 'stimulus';

import Vue from 'vue/dist/vue.esm';
import PixieSelect from '../components/form/PixieSelect';
import Pixie from '../shared/globals';

// This is a stimulus controller that replaces a standard select with a PixieSelect component.
// It reads the attributes from the html element and converts them to props.
export default class extends Controller {
  connect() {
    const el = this.element;
    const disabled = el.disabled || false;
    const placeholder = el.attributes.placeholder ? el.attributes.placeholder.value : undefined;
    const dataValueMissing = el.dataset['value-missing'];
    const value = el.dataset['default'] || undefined;
    const name = el.name;
    const shouldSubmit = el.dataset.submit || false;
    const onchange = el.onchange;
    const onChangeBusEvent = el.dataset.onChangeBusEvent;
    const bindTo = el.dataset.bind;
    const search = el.dataset.search ? true : false;

    let options = [...el.options];
    options = options.map(({ value, label, selected, dataset }) => {
      const { src } = dataset;
      return { value, label, selected, src };
    });

    const data = {
      name,
      options,
      placeholder,
      dataValueMissing,
      value,
      disabled,
      bindTo,
      search
    };

    new Vue({
      el,
      name: 'PixieSelectController',
      components: {
        PixieSelect
      },
      data() {
        return data;
      },
      methods: {
        handleChange(value) {
          if (onchange) {
            onchange.call(value);
          }

          if (shouldSubmit) {
            this.$nextTick(() => {
              this.$el.closest('form').submit();
            });
          }

          if (onChangeBusEvent) {
            Pixie.EventBus.$emit(onChangeBusEvent, value);
          }
        }
      },
      template: `
        <span>
          <pixie-select
            @input="handleChange"
            :search="search"
            :name="name"
            :dataValueMissing="dataValueMissing"
            :options="options"
            :placeholder="placeholder"
            :value="value"
            :disabled="disabled">
          </pixie-select>
        </span>`
    });
  }
}
