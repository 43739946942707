import Vue from 'vue/dist/vue.esm.js';

import VuePikaday from '@enrian/vue-pikaday';
import Sortable from 'sortablejs/Sortable.min.js';
import VueTippy, { TippyComponent } from 'vue-tippy';
import VeeValidate from 'vee-validate';

import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import en_messages from '../components/i18n/messages.en';
import moment from 'moment';

// syncfusion
// Registering Syncfusion license key
import { registerLicense, validateLicense } from '@syncfusion/ej2-base';


const syncfusionLicence = 'ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Sd0NjUXxZcHxXQGVf;Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jTH5ad0VmWnxbdnxTTg==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Tc0VqWH9bc3dQQWBYWQ==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkBjUH5edXdWQ2deVkw=;MTk4MjIyOEAzMjMwMmUzNDJlMzBBSlh0bVRMUno2eGVXcndiYXU0Zjkwa0QvcXAzRWNZRk9DbVN2WHVzZzNVPQ==;MTk4MjIyOUAzMjMwMmUzNDJlMzBFM2p4TUluT0xLamNtVHV3bFNvMDdqVk1pSnM4bHk5bzJybHo2T0J0SUFzPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVmWXZfcHFWRWVUUEF+;MTk4MjIzMUAzMjMwMmUzNDJlMzBtNk1ZaU9NTUdPMktGYWxVUitEd1FyeVVPc3lTSncwUmIyaWZ3NzRrMGxVPQ==;MTk4MjIzMkAzMjMwMmUzNDJlMzBPWUR1OUJkVWFJTStBUk9hdnlienpwb1Q5Ry9kbHFzcU5JRDN1RUZIaFFvPQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkBjUH5edXdWQ2hVU0w=;MTk4MjIzNEAzMjMwMmUzNDJlMzBWcWJ5TTJycW5Cc1M2TW9kb2FteEhUVlFHQVBDUnhQK1VHN05rUEdyVm5vPQ==;MTk4MjIzNUAzMjMwMmUzNDJlMzBtbGpLM29HUFpQZWdQZGlaa3dmbTNuSVlZVjRvdktVRnQvTjlOOEMyREJnPQ==;MTk4MjIzNkAzMjMwMmUzNDJlMzBtNk1ZaU9NTUdPMktGYWxVUitEd1FyeVVPc3lTSncwUmIyaWZ3NzRrMGxVPQ==';

registerLicense(syncfusionLicence);
validateLicense(syncfusionLicence);

import { ChartPlugin } from '@syncfusion/ej2-vue-charts';
import { SkeletonPlugin } from '@syncfusion/ej2-vue-notifications';
import { DashboardLayoutPlugin } from '@syncfusion/ej2-vue-layouts';
import { AccumulationChartPlugin } from '@syncfusion/ej2-vue-charts';

import vSelect from 'vue-select';

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement => createElement('span', '')
  }
});

// directives
import '../directives/click-outside';

Vue.use(ChartPlugin);
Vue.use(SkeletonPlugin);
Vue.use(DashboardLayoutPlugin);
Vue.use(AccumulationChartPlugin);
Vue.use(VuePikaday);
Vue.use(VueI18Next);
Vue.use(VueTippy);
Vue.use(VeeValidate);
Vue.component('tippy', TippyComponent);
Vue.component('v-select', vSelect);

i18next.init({
  lng: 'en',
  fallbackNS: ['translation'],
  debug: false,
  resources: {
    en: {
      translation: en_messages
    }
  }
});

const i18n = new VueI18Next(i18next);

Vue.directive('sortable', {
  inserted: (el, binding) => {
    new Sortable(el, binding.value || {});
  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('D MMM YYYY hh:mm a');
  }
});

// Share EventBus between different applications
if (window.PixieEventBus === undefined) {
  window.PixieEventBus = new Vue();
}

export default {
  createVueApp: function(opts) {
    const tmpVar = '_app' + Date.now();
    opts['i18n'] = i18n;
    window[tmpVar] = new Vue(opts);
    return window[tmpVar];
  },
  EventBus: window.PixieEventBus
};
